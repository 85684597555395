import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, Modal, Autocomplete, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

toast.configure();

const DebitNoteRegister = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  // Get the current date
  const currentDate = new Date();

  // Set the date to the 1st of April of the current year
  currentDate.setDate(1);
  currentDate.setMonth(4 - 1); // Months are zero-based, so April is 3
  const date = new Date();
  const [value, setValue] = useState(0);
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(date);
  const [fromdatesummary, setfromdateSummary] = useState(date);
  const [todatesummary, settodateSummary] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [reportTypeValue, setReportTypeValue] = useState("SUM");
  const reportType = [
    {
      name: "Summary",
      value: "SUM",
    },
    {
      name: "Detail",
      value: "DET",
    },
  ];

  //fetch state data
  async function getfromdatedata() {
    try {
      const response = await fetch("/reports/fromdate", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setfromdateSummary(jsonData.fromdate);
      setfromdate(jsonData.fromdate);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfromdatedata();
  }, []);

  const handleReport = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select From Date!!");
    }
    try {
      const body = {
        fromdate,
        todate,
        format: 1,
        reportType: reportTypeValue,
      };
      console.log(body);
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const response = await fetch("/reports/debitnoteregister", options)
        .then((response) => response.blob())
        .then((pdfBlob) => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfStream(pdfUrl);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const body = {
        fromdate,
        todate,
        format: option,
        reportType: reportTypeValue,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const fromDateStr = fromdate;
      const toDateStr = todate.toISOString();
      const res = await fetch("/reports/debitnoteregister", options);
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `DebitNoteRegister_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `DebitNoteRegister_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);

  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select From Date!!");
    }
    try {
      const body = { fromdate, todate, reportType: reportTypeValue };
      const data = await fetch("/reports/debitnoteregister/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      if (reportTypeValue === "SUM") {
        downloadLink.download = "DebitnoteSummary.xlsx";
      } else {
        downloadLink.download = "DebitnoteDetail.xlsx";
      }

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"DEBIT NOTE REGISTER"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box sx={{ width: "100%" }}>
              <Stack
                spacing={2}
                columnGap={2}
                direction="row"
                mt={5}
                mx={2}
                mb={4}
              >
                <TextField
                  id="receipt"
                  name="receipt"
                  required
                  select
                  label="Receipt Type"
                  value={reportTypeValue}
                  onChange={(e) => setReportTypeValue(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "20%",
                  }}
                  size="small"
                >
                  {reportType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromdate}
                    onChange={(newValue) => {
                      setfromdate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: "20%",
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date"
                    inputFormat="DD/MM/YYYY"
                    value={todate}
                    onChange={(newValue) => {
                      settodate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        sx={{
                          width: "20%",
                        }}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  type="submit"
                  onClick={handleReport}
                  variant="contained"
                  component="label"
                  size="medium"
                  sx={{ boxShadow: "2px 2px 6px  #404142" }}
                  endIcon={<Task />}
                >
                  Report
                </Button>
                <Button
                  onClick={printexcel}
                  size="small"
                  variant="contained"
                  color="success"
                  //endIcon={<PrintIcon />}
                >
                  Excel
                </Button>
              </Stack>
              {pdfStream && (
                <ReportNavigation
                  reportName={"Debit Note Register"}
                  onDownload={handleDownload}
                />
              )}
              <Box ref={myElementRef}></Box>
              <Box ref={myElementRef} sx={{ backgroundColor: "white" }}>
                {pdfStream && (
                  <iframe
                    src={pdfStream}
                    width="100%"
                    height="480px"
                    title="report"
                  ></iframe>
                )}
              </Box>
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default DebitNoteRegister;
