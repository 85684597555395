// MobileView.jsx
import React from 'react';
import { Box, Typography, Paper, Container } from '@mui/material';
import MonitorIcon from '@mui/icons-material/Monitor';
import './MobileView.css';

const MobileView = () => {
  return (
    <Box className="mobile-view-container">
      <Container maxWidth="sm">
        <Paper elevation={3} className="mobile-view-paper">
          <MonitorIcon className="desktop-icon" />
          <Typography variant="h4" component="h1" gutterBottom>
            Desktop Only
          </Typography>
          <Typography variant="body1" gutterBottom>
            We apologize, but this application is optimized for desktop use only. 
            Please access the site from a desktop computer for the best experience.
          </Typography>
          <Box className="recommendation-box">
            <Typography variant="body2" color="primary">
              Recommended minimum screen width: 1024px
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default MobileView;