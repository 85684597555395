import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState,useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const TyreTubeSale = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };
  const date = new Date();
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(date);

  //fetch state data
  async function getfromdatedata() {
    try {
      const response = await fetch("/reports/fromdate", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setfromdate(jsonData.fromdate);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfromdatedata();
  }, []);

  const [pdfStream, setPdfStream] = useState(null);
  const [reportTypeValue, setReportTypeValue] = useState("SUM");
  const reportType = [
    {
      name: "DETAIL-DATEWISE",
      value: "DTL",
    },
    {
      name: "SUMMARY-SALESMANWISE",
      value: "SUM",
    },
  ];
  const [itemcatgValue, setitemcatgValue] = useState("JK");
  const itemcatg = [
    {
      name: "JK TYRE",
      value: "JK",
    },
    {
      name: "METRO",
      value: "MT",
    },
    {
      name: "OTHER",
      value: "OTH",
    },
  ];
  //report
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      if (fromdate === null) {
        toast.error("Please Select A valid Date!");
      } else {
        const body = { reportTypeValue, fromdate, todate, itemcatgValue };
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch(
          "/reports/spare/tyretube/download",
          options
        )
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"TYRE & TUBE SALE REGISTER"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <TextField
                id="receipt"
                name="receipt"
                required
                select
                label="Receipt Type"
                value={reportTypeValue}
                onChange={(e) => setReportTypeValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {reportType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="itemcatg"
                name="itemcatg"
                required
                select
                label="Product Type"
                value={itemcatgValue}
                onChange={(e) => setitemcatgValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "20%",
                }}
                size="small"
              >
                {itemcatg.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                Show Report
              </Button>
              {/* <Button
              onClick={printexcel}
              size="small"
              variant="contained"
              color="success"
              //endIcon={<PrintIcon />}
            >
              Excel
            </Button> */}
            </Box>
            {/* {pdfStream && (
            <ReportNavigation
              onDownload={handleDownload}
              reportName={"GOVT GST"}
            />
          )} */}
            <Box sx={{ backgroundColor: "white", mt: 2 }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default TyreTubeSale;
