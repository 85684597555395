import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

toast.configure();

const PartyMonthwiseSale = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const currentDate = new Date();

  // Set the date to the 1st of April of the current year
  currentDate.setDate(1);
  currentDate.setMonth(4 - 1); // Months are zero-based, so April is 3

  // Use the useState hook to manage the state
  const date = new Date();
  const categories = [
    {
      name: "Party",
      value: 2,
    },
    {
      name: "Salesman",
      value: 1,
    },
  ];

  const sortType = [
    {
      name: "Bill",
      value: "bl",
    },
    {
      name: "Payment",
      value: "py",
    },
  ];
  const [catgvalue, setCatgValue] = useState(1);
  const [sortTypeValue, setSortTypeValue] = useState("bl");
  const [fromdate, setfromdate] = useState(currentDate);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);

    //fetch state data
    async function getfromdatedata() {
      try {
        const response = await fetch("/reports/fromdate", {
          method: "GET",
          headers: { sptoken: userToken },
        });
        const jsonData = await response.json();
        setfromdate(jsonData.fromdate);
      } catch (err) {
        console.error(err.message);
      }
    }
    useEffect(() => {
      getfromdatedata();
    }, []);

  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      if (todate === null) {
        setLoading(false)
        toast.error("Please Select A valid Date!");
      } else {
        const body = {
          fromdate,
          todate,
          format: 1,
          poption: catgvalue,
          psource: sortTypeValue,
        };
        console.log(body);
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch(
          "/reports/spare/partymonthwisesale",
          options
        )
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
          setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const body = {
        fromdate,
        todate,
        format: option,
        poption: catgvalue,
        psource: sortTypeValue,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          sptoken: userToken,
        },
        method: "POST",
        body: JSON.stringify(body),
      };
      const fromDateStr = fromdate;
      const toDateStr = todate.toISOString();
      const res = await fetch("/reports/spare/partymonthwisesale", options);
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PartyMonthWiseSale_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PartyMonthWiseSale_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  const myElementRef = useRef(null);

  useEffect(() => {
    // Step 3: Scroll to the element when the component mounts
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pdfStream]);

  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const body = {
        fromdate,
        todate,
        poption: catgvalue,
        psource: sortTypeValue,
      };
      console.log(body);
      const data = await fetch("/reports/spare/partymonthwisesale/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

        downloadLink.download = "PartyMonthWiseSale.xlsx";
      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Sidebar
        Title={"PARTY MONTHWISE SALE"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              // spacing={2}
              columnGap={2}
              direction="row"
              mt={5}
              mx={5}
              mb={4}
            >
              <TextField
                id="options"
                name="options"
                required
                select
                label="Options"
                value={catgvalue}
                onChange={(e) => setCatgValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "30%",
                }}
                size="small"
              >
                {categories.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="source"
                name="source"
                required
                select
                label="Source"
                value={sortTypeValue}
                onChange={(e) => setSortTypeValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "30%",
                }}
                size="small"
              >
                {sortType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "25%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "25%",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                disabled={loading===true}
                size="medium"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Loading..." : "Report"}
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                //endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Stack>
            <Box ref={myElementRef}></Box>
            {pdfStream && (
              <ReportNavigation
                reportName={"PARTY MONTH WISE SALE"}
                onDownload={handleDownload}
              />
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="480px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default PartyMonthwiseSale;
