import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState,useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

toast.configure();
const PickerPerformance = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const date = new Date();
  const options = [
    {
      name: "Picker",
      value: 1,
    },
    {
      name: "Date",
      value: 2,
    },
  ];
  const [optvalue, setOptValue] = useState(1);
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);

  //fetch state data
  async function getfromdatedata() {
    try {
      const response = await fetch("/reports/fromdate", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setfromdate(jsonData.fromdate);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfromdatedata();
  }, []);

  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (fromdate === null) {
      setLoading(false)
      return toast.error("Please Enter Date");
    }
    try {
      const fromDateStr = fromdate;
      const toDateStr = todate.toISOString();
      const res = await fetch(
        `/reports/spare/pickerperformance/${optvalue}/${fromDateStr}/${toDateStr}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const data = await res.text();
      setPdfStream(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error.message);
    }
  };
  const handleDownload = async (option, reportName) => {
    try {
      const fromDateStr = fromdate;
      const toDateStr = todate.toISOString();
      const res = await fetch(
        `/reports/spare/pickerperformance/download/${optvalue}/${option}/${fromDateStr}/${toDateStr}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      if (!res.ok) {
        throw new Error(`Failed to fetch the PDF. Status: ${res.status}`);
      }
      if (parseInt(option) === 1) {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PickerPerformance_${toDateStr}.pdf`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      } else {
        const blob = await res.blob(); // Convert response to a Blob
        const filename = `PickerPerformance_${toDateStr}.xlsx`; // Set the desired file name for the download
        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Programmatically click the link to initiate download
        link.click();

        // Clean up resources
        URL.revokeObjectURL(link.href);
      }
    } catch (error) {
      console.log(error.message);
    }
    console.log(`Downloading ${option} report: ${reportName}`);
  };
  //formatted excel
  const printExcel = async (e) => {
    e.preventDefault();
    try {
      const fromDateStr = fromdate;
      const toDateStr = todate.toISOString();
      const res = await fetch(
        `/reports/spare/pickerperformance/download/formattedexcel/${fromDateStr}/${toDateStr}`,
        {
          method: "GET",
          headers: {
            sptoken: userToken,
          },
        }
      );
      const blob = await res.blob(); // Convert response to a Blob
      const currentDate = new Date();

      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
      const year = currentDate.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      const filename = `PickerPerformance_${formattedDate}.xlsx`; // Change the extension to .xlsx for Excel
      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to initiate download
      link.click();

      // Clean up resources
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Sidebar
        Title={"PICKER PERFORMANCE"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              <TextField
                id="sorted on"
                name="sorted on"
                required
                select
                label="Sort By"
                value={optvalue}
                onChange={(e) => setOptValue(e.target.value)}
                variant="outlined"
                sx={{
                  width: "120px",
                  backgroundColor: "white",
                }}
                size="small"
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                        backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                        backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                onClick={handleReport}
                variant="contained"
                disabled={loading===true}
                size="small"
                sx={{ width: "auto", boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
               {loading ? "Fetching Data..." : " Show Report"}
              </Button>
              <Button
                disabled={fromdate === null}
                onClick={printExcel}
                color="success"
                variant="contained"
                size="small"
                sx={{ width: "auto", boxShadow: "2px 2px 6px  #404142" }}
                // endIcon={<Task />}
              >
                Print Excel
              </Button>
            </Box>
            {pdfStream && (
              <ReportNavigation
                onDownload={handleDownload}
                reportName={"pickerperformance"}
              />
            )}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  srcDoc={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </>
  );
};

export default PickerPerformance;
