import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PrintIcon from "@mui/icons-material/Print";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
toast.configure();
toast.configure();

///////////////////////////// ARROW COMPONENT STARTS HERE ////////////////////////////////////////////
const SalesmanwiseBilldetail = () => {
  const OPTIONS_LIMIT = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);

  // TEXT FIELD VALUE STATES
  const [billType, setBillType] = useState(1);
  const saletypeopts = [
    {
      value: 1,
      label: "Bill",
    },
    {
      value: 2,
      label: "Order",
    },
    {
      value: 3,
      label: "Receipt",
    },
  ];
  const [reporttype, setreporttype] = useState(1);
  const reporttypeopts = [
    {
      value: 1,
      label: "Summary",
    },
    {
      value: 2,
      label: "Details",
    },
  ];
  const [salesmantype, setsalesmantype] = useState(0);
  //console.log(salesmantype)
  const salesmantypeopts = [
    {
      value: 0,
      label: "All",
    },
    {
      value: 1,
      label: "Selective",
    },
  ];
  const [itemgroup, setitemgroup] = useState(null);
  const [itemgroupopts, setitemgroupopts] = useState([]);
  const [salesmaninput, setsalesmaninput] = useState(null);
  //console.log(salesmaninput);
  const [salesmanopts, setsalesmanopts] = useState([]);
  const [partycatginput, setpartycatginput] = useState(null);
  const [partycatgopts, setpartycatgopts] = useState([]);
  const date = new Date();
  const [fromdate, setfromdate] = useState(date);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const [loading, setLoading] = useState(false);

  //fetch state data
  async function getfromdatedata() {
    try {
      const response = await fetch("/reports/fromdate", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setfromdate(jsonData.fromdate);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfromdatedata();
  }, []);

  //itemcatg
  async function getitemcatg() {
    try {
      const res = await fetch("/reports/salesmanreport/item/catg", {
        method: "GET",
        headers: {
          sptoken: userToken,
        },
      });
      const data = await res.json();
      //console.log(data);
      setitemgroupopts(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getitemcatg();
  }, []);

  //salesman
  async function getallsalesman() {
    try {
      const response = await fetch("/reports/salesmanreport/salesman", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      //console.log(jsonData);
      // setallparts(jsonData);
      setsalesmanopts(jsonData);
      setsalesmaninput({ salesmandetails: "All", salesmanid: 0 });
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getallsalesman();
  }, []);

  //party category
    async function getallpartycatg() {
      try {
        const response = await fetch("/reports/salesmanreport/partycatg", {
          method: "GET",
          headers: { sptoken: userToken },
        });
        const jsonData = await response.json();
        setpartycatgopts(jsonData);
        setpartycatginput({ subledgercatgname: "All", subledgercatgid: 0 });
      } catch (err) {
        console.error(err.message);
      }
    }
    useEffect(() => {
      getallpartycatg();
    }, []);

  const handleReport = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (fromdate === null) {
        setLoading(false);
        toast.error("Please Select A valid Date!");
      } else {
        const body = {
          fromdate,
          todate,
          billType,
          reporttype,
          itemgroup: itemgroup ? itemgroup.groupid : null,
          salesmanid: salesmaninput.salesmanid,
          subledgercatgid:partycatginput.subledgercatgid
        };
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };
        const response = await fetch(
          "/reports/salesmanreportbilldetail",
          options
        )
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };
  //print excel
  const printexcel = async (e) => {
    e.preventDefault();
    try {
      const body = {
        fromdate,
        todate,
        billType,
        reporttype,
        itemgroup: itemgroup ? itemgroup.groupid : null,
        salesmanid: salesmaninput.salesmanid,
        subledgercatgid:partycatginput.subledgercatgid
      };
      const data = await fetch("/reports/salesmanreportbilldetail/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "SalesManWiseReport.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"SALESMAN WISE REGISTER"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={4}
              columnGap={2}
              direction="row"
              mt={1}
              mx={2}
              mb={4}
            >
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Options
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={"1"}
                  name="radio-buttons-group"
                  row
                >
                  {saletypeopts.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={<Radio />}
                      label={opt.label}
                      onChange={(e) => setBillType(e.target.value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Report Type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={1}
                  name="radio-buttons-group"
                  row
                >
                  {reporttypeopts.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={<Radio />}
                      label={opt.label}
                      onChange={(e) => setreporttype(e.target.value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                rowGap:2,
                justifyContent: "center",
              }}
            >
              <Autocomplete
                id="Salesman"
                value={salesmaninput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setsalesmaninput(newValue);
                }}
                options={salesmanopts}
                getOptionLabel={(option) => option.salesmandetails}
                sx={{
                  width: 200,
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} label="Salesman" placeholder="Salesman" />
                )}
                size="small"
              />
              <Autocomplete
                id="Salesman"
                value={partycatginput}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setpartycatginput(newValue);
                }}
                options={partycatgopts}
                getOptionLabel={(option) => option.subledgercatgname}
                sx={{
                  width: 200,
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} label="Party Category" placeholder="Party Category" />
                )}
                size="small"
              />

              <Autocomplete
                id="makersearch"
                value={itemgroup}
                filterOptions={filterOptions2}
                onChange={(event, newValue) => {
                  setitemgroup(newValue);
                }}
                options={itemgroupopts}
                getOptionLabel={(option) => option.groupname}
                // isOptionEqualToValue={(option, value) =>
                //   option.makerpickerid === value.makerpickerid
                // }
                sx={{
                  width: 200,
                }}
                renderInput={(billopts) => (
                  <TextField required {...billopts} placeholder="Item Group" />
                )}
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: 200,
                        //backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: 200,
                        //backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                disabled={loading === true}
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                {loading ? "Fetching Data..." : " Show Report"}
              </Button>
              <Button
                onClick={printexcel}
                size="small"
                variant="contained"
                color="success"
                endIcon={<PrintIcon />}
              >
                Excel
              </Button>
            </Box>
            {pdfStream && <ReportNavigation />}
            <Box sx={{ backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default SalesmanwiseBilldetail;
