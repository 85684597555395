import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Box, Stack, Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom";
import Task from "@mui/icons-material/Task";
import ReportNavigation from "../../Components/ReportNavigation/ReportNavigation";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
toast.configure();

const DeliveryNoteReg = () => {
  //GET THE TOKEN FROM HELPER CLASS

  const userToken = LocalstorageHelper.GetUserToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/sparepartsLogin" />;
    }
  };

  //TIMEOUT WHEN JWT EXPIRES
  setTimeout(() => {
    window.location.reload(false);
  }, 1800000);
  const OPTIONS_LIMIT = 15;
  const filterOptions2 = createFilterOptions({
    limit: OPTIONS_LIMIT,
    trim: true,
  });
  // TEXT FIELD VALUE STATES
  const date = new Date();
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(date);
  const [pdfStream, setPdfStream] = useState(null);
  const reporttypeopts = [
    {
      value: 0,
      label: "ALL",
    },
    {
      value: 1,
      label: "Selective",
    },
  ];

  //fetch state data
  async function getfromdatedata() {
    try {
      const response = await fetch("/reports/fromdate", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setfromdate(jsonData.fromdate);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getfromdatedata();
  }, []);

  const [reporttype, setreporttype] = useState(0);
  const [partyInput, setPartyInput] = useState(null);
  const [partyopts, setpartyOpts] = useState([]);
  async function getpartyOpts() {
    try {
      const response = await fetch("/deliverynote/reg/party", {
        method: "GET",
        headers: { sptoken: userToken },
      });
      const jsonData = await response.json();
      setpartyOpts(jsonData);
      // console.log(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getpartyOpts();
  }, []);

  //report
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      if (fromdate === null) {
        toast.error("Please Select A valid Date!");
      } else {
        const body = {
          fromdate,
          todate,
          subledgerid: parseInt(reporttype) === 0 ? 0 : partyInput?.subledgerid,
        };
        const options = {
          headers: {
            "Content-Type": "application/json",
            sptoken: userToken,
          },
          method: "POST",
          body: JSON.stringify(body),
        };

        const response = await fetch("/deliverynote/reg/report", options)
          .then((response) => response.blob())
          .then((pdfBlob) => {
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfStream(pdfUrl);
          })
          .catch((error) => console.error(error));
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  //formatted excel
  const printexcel = async (e) => {
    e.preventDefault();
    if (fromdate === null) {
      return toast.error("Please Select The From Date!!");
    }
    try {
      const fromDateStr = fromdate;
      const toDateStr = todate.toISOString();
      const body = {
        fromdate: fromDateStr,
        todate: toDateStr,
        subledgerid: parseInt(reporttype) === 0 ? 0 : partyInput?.subledgerid,
      };
      const data = await fetch("/deliverynote/reg/excel", {
        method: "POST",
        headers: {
          sptoken: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const blob = await data.blob();

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "DeliveryNoteRegister.xlsx";

      // Append the link to the body
      document.body.appendChild(downloadLink);

      // Trigger the click event to start the download
      downloadLink.click();

      // Remove the link from the body
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <Sidebar
        Title={"Delivery Note Register"}
        expandedPanel={"panel3"}
        children={
          <Box
            component="form"
            sx={{
              width: "100%",
            }}
            //onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Stack
              spacing={10}
              columnGap={2}
              direction="row"
              mt={2}
              mx={20}
              mb={2}
            >
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Party Type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={0}
                  name="radio-buttons-group"
                  row
                >
                  {reporttypeopts.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={<Radio />}
                      label={opt.label}
                      onChange={(e) => setreporttype(e.target.value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Box
              sx={{
                marginTop: "4rem",
                display: "flex",
                flexWrap: "wrap",
                columnGap: "20px",
                justifyContent: "center",
              }}
            >
              {parseFloat(reporttype) === 1 ? (
                <>
                  <Autocomplete
                    id="partysearch"
                    value={partyInput}
                    filterOptions={filterOptions2}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setPartyInput();
                      } else {
                        setPartyInput(newValue);
                      }
                    }}
                    options={partyopts}
                    getOptionLabel={(option) => option.subledgername}
                    sx={{
                      width: 250,
                    }}
                    renderInput={(billopts) => (
                      <TextField
                        required
                        {...billopts}
                        placeholder="Party Details"
                      />
                    )}
                    size="small"
                  />
                </>
              ) : (
                <></>
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromdate}
                  onChange={(newValue) => {
                    setfromdate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                        backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={todate}
                  onChange={(newValue) => {
                    settodate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      sx={{
                        width: "20%",
                        backgroundColor: "white",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>

              <Button
                type="submit"
                onClick={handleReport}
                variant="contained"
                component="label"
                size="large"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                endIcon={<Task />}
              >
                Show Report
              </Button>
              <Button
                type="submit"
                onClick={printexcel}
                variant="contained"
                component="label"
                size="large"
                color="success"
                sx={{ boxShadow: "2px 2px 6px  #404142" }}
                // endIcon={<Task />}
              >
                Excel
              </Button>
            </Box>
            {/* {pdfStream && <ReportNavigation />} */}
            <Box sx={{ mt: 5, backgroundColor: "white" }}>
              {pdfStream && (
                <iframe
                  src={pdfStream}
                  width="100%"
                  height="680px"
                  title="report"
                ></iframe>
              )}
            </Box>
          </Box>
        }
      />
      {/* <FooterElement /> */}
      {redirectToLoginPage()}
    </div>
  );
};

export default DeliveryNoteReg;
