import React, { useState } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  Stack,
  Paper,
} from "@mui/material";
import AdminSidebar from "../../Components/AdminPanel/AdminSidebar";
import LocalstorageHelper from "../../LocalStorageHelper/LocalstorageHelper";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const BackupDatabase = () => {
  const userToken = LocalstorageHelper.GetAdminToken();

  const redirectToLoginPage = () => {
    if (!userToken) {
      return <Redirect exact to="/" />;
    }
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleBackup = async () => {
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      // Call the API to trigger the database backup
      const response = await fetch("/admin/db/backup", {
        method: "POST",
        headers: { "Content-Type": "application/json", token: userToken },
      });

      if (!response.ok) {
        throw new Error("Failed to create the backup");
      }

      // Convert the response to a blob
      const blob = await response.blob();

      // Create a download link for the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Extract the file name from the response headers or set a default name
      const contentDisposition = response.headers.get("Content-Disposition");
      const fileName =
        contentDisposition?.split("filename=")[1]?.replace(/"/g, "") ||
        `db_backup_${Date.now()}.sql`;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSuccess(true);
    } catch (err) {
      console.error("Error downloading the backup:", err);
      setError("Failed to create or download the backup. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AdminSidebar />
      <Stack spacing={2} columnGap={2} direction="row" justifyContent={"center"} mt={2}  mb={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Paper
            sx={{
              padding: 4,
              maxWidth: 500,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" gutterBottom>
              Database Backup
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
              Click the button below to create a backup of your database and
              download it.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBackup}
              disabled={loading}
              sx={{ mb: 2, padding: "10px 20px", fontSize: "16px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Download Backup"
              )}
            </Button>
            {success && (
              <Typography variant="body1" color="success.main" sx={{ mb: 2 }}>
                Backup downloaded successfully!
              </Typography>
            )}
            {error && (
              <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
          </Paper>
        </Box>
      </Stack>
      {redirectToLoginPage()}
    </>
  );
};

export default BackupDatabase;
